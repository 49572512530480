import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";

const callToActionData = {
    title: "Development Principles",
    subtitle: "Our development principles form the basis for everything we do at Plinthstone. From the people we hire to strategies we develop or how we build partnerships. This defines our working behaviors and practices. If something doesn’t qualify these principles; we simply don’t do it. This is our secret sauce for success.",
    btnText: ["Authentic Influence","Honest Practice","Value Creation","Lasting Association"],
}

const CalltoActionFive = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-5">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h2 className="title">{callToActionData.title}</h2>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h6 className="subtitle">{callToActionData.subtitle}</h6>
                                </ScrollAnimation>

                               
                                <ScrollAnimation  className="col-12 row"
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    {callToActionData.btnText.map((data, index) => (
                                    <div className="call-to-btn text-center col-md-3 col-6">
                                        <a className="btn-default btn-icon" href="#">{data} </a>
                                    </div>
                                    ))}
                                </ScrollAnimation>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionFive;