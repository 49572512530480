import React from 'react';
import { FiArrowDown, FiArrowRight } from "react-icons/fi";
const callToActionData = {
    title: "We believe in dreams, in goodness and sustainable relationships.",
    subtitle: "Plinthstone is a multi-speciality realty advisory company that is founded on the vision that trusted partnerships achieve ambitions; that values and intelligence can converge to form a revolutionary brand & business experience.",
    btnText: "",
}

const CalltoActionEight = () => {
    return (
        <div className="rn-callto-action rn-call-to-action style-8 content-wrapper">
            <div className="container">
                <div className="row row--0 align-items-center ">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title">{callToActionData.title}</h2>
                                <p>{callToActionData.subtitle}</p>
                                <div className="call-to-btn text-center mt--30">
                                    <a className="btn-default btn-icon custom no-shadow" href="#spectrum">{callToActionData.btnText} <i className="icon"><FiArrowDown /></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionEight;