import React from 'react';
import VideoTwo from '../video/VideoTwo';
import { FiCheck } from "react-icons/fi";
import CounterUpTwo from "../counterup/CounterUpTwo";

const PopupData = [
    {
        id: "01",
        image: "./images/bg/bg-image-4.jpg",
        popupLink: [
            'https://www.youtube.com/watch?v=jJomhcYetkM',
        ],
    }
]

const AboutFour = ({image}) => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                        {PopupData.map((item) => (
                            <div className="video-btn" key={item.id}>
                                <VideoTwo imagename={`${image}`} galleryItem={item} />
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h2 className="title" style={{textTransform:'uppercase',fontWeight:'800'}}>we believe in <strong>dream.</strong></h2>
                                <ul className="feature-list">
                                    <li>                                        
                                        <div className="title-wrapper">
                                            {/* <h4 className="title">A good traveler has no fixed plans</h4> */}
                                            <p className="text">Plinthstone is a multi-speciality realty advisory company that is founded on the vision that trusted partnerships achieve ambitions; that values and intelligence can converge to form a revolutionary brand & business experience.</p>
                                            <p className="text"> Our services are designed to catalyst growth & sustenance for our partners in a way that is unique and unexplored in the marketplace.</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="about-btn mt--30">
                                    <a className="btn-default" href="#">Know more</a>
                                </div>
                                {/* <CounterUpTwo column="col-lg-4 col-md-6 col-sm-6 col-12" counterStyle="counter-style-2 border-style" textALign="text-left" />  */}
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour
