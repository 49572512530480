import React from 'react'
import {Link} from "react-router-dom";
import { FiArrowRight, FiArrowDown } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import Separator from "../elements/separator/Separator";
import TimelineOne from "../elements/timeline/TimelineOne";
import VideoItem from "../elements/video/VideoItem";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import CalltoActionEight from "../elements/calltoaction/CalltoActionEight";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import SlpitOne from '../elements/split/SlpitOne';
import SlpitFive from '../elements/split/SlpitFive';
import SlpitSix from '../elements/split/SlpitSix';
import CalltoActionTwo from "../elements/calltoaction/CalltoActionTwo";
import BrandOne from "../elements/brand/BrandOne";
import Mission from "../elements/about/Mission";
import TeamOne from "../elements/team/TeamOne";

var BlogListData = BlogClassicData.slice(0, 3);


const PopupData = [
    {
        id: "01",
        image: "./images/bg/bg-image-4.jpg",
        popupLink: [
            'https://www.youtube.com/watch?v=jJomhcYetkM',
        ],
    }
]

const BannerData = [
    {
        image: "./images/bg/photo-1486406146926-c627a92ad1ab.jpg",
        title: "POWERING DREAMS",
        description: "Our services are designed to catalyst growth & sustenance for our partners in a way that is unique and unexplored in the marketplace."
    },
    {
        image: "./images/bg/bg-image-8.jpg",
        title: "POWERING DREAMS",
        description: "We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials."
    },
    {
        image: "./images/bg/photo-1496568816309-51d7c20e3b21.jpg",
        title: "POWERING DREAMS",
        description: "We help our clients succeed by creating brand identities, <br />  digital experiences, and print materials."
    },
]

const HomeDefault = () => {
    
    return (
        <>
            <SEO title="Powering Dreams" />
            <main className="page-wrapper">
               {/*  <HeaderTopNews /> */}
                <HeaderOne  btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="height-950 bg_image" data-black-overlay="1" style={{backgroundImage: `url(${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="col-12">
                                            <div className="inner text-center">
                                                <h1 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h1>
                                                {/* <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p> */}
                                                <div className="button-group">
                                                    <a className="btn-default btn-medium round btn-icon" target="_blank" href="#">View More<i className="icon"><FiArrowRight /></i></a>
                                                    <Link className="btn-default btn-medium btn-border round btn-icon" to="#">Contact Us <i className="icon"><FiArrowRight /></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}

                {/* Start Elements Area  */}
              
                        
                                <div className="col-lg-6 offset-lg-3 mb--40">
                                        <CalltoActionEight />
                                </div>
                        
                    {/* End Elements Area  */}

<Separator/>
                {/* Start Elements Area  */}
                    <div id="spectrum" className="row">
                        <div className="col-lg-12">
                        <SlpitOne />
                        </div>
                    </div>
                           
                {/* End Elements Area  */}
             
                {/* Start Elements Area  */}
                    <div className="row">
                            <div className="col-lg-12">
                            <SlpitFive />
                            </div>
                    </div>
                {/* End Elements Area  */}

                {/* Start Elements Area  */}
                <div className="row">
                            <div className="col-lg-12">
                            <SlpitSix />
                            </div>
                    </div>
                {/* End Elements Area  */}


                {/* Start Video Area  */}
                <div className="rwt-video-area rn-section-gapBottom">
                    <div>
                        <div className="row">
                            {PopupData.map((item) => (
                                <div className="col-lg-12" key={item.id}>
                                    <VideoItem galleryItem={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Video Area  */}


              

              

                {/* Start Brand Area  */}
                <div className="rwt-brand-area pb-0 rn-section-top ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Partners"
                                        title = "We have made <br/>positive differences to"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt--40">
                                    <BrandOne brandStyle="brand-style-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Brand Area  */}
                   

                    <div className="rwt-team-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb-5">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Our Experts."
                                            title = "The dream team"
                                            description = ""
                                        />
                                </div>
                            </div>
                            <TeamOne column="col-lg-3 col-md-4 col-12 " teamStyle="team-style-default" />
                        </div>
                    </div>
                    {/* End Elements Area  */}

                <FooterOne />
                <Copyright />                    

            </main>
            
        </>
    )
}
export default HomeDefault

