import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        date: "Step-1",
        title: "Finance & Asset Management",
        description: "Our remarkable finance & asset management services range from brand to business, capturing critical functions of the two. Our unique financial advisory is transformational and has benefitted several companies. Our finance assistance & Cashflow management has proved useful to bring business successes.",
        image: "timeline-01",
        workingStep: [
            "Project Planning & Review",
"Project Research And Insights",
"Featuring The Project",
"Concept Development",
"Rera Compliance",
"Funding For The Project",
"Partnerships & Associations",
"Project Representation",
"Project Lifecycle Supervision"

        ]
    },
     {
        id: "2",
        date: "Step-2",
        title: "Cashflow Generation Through Mandate",
        description: "Our business solutions to partner developers is carefully calibrated to meet both tactical and strategic challenges. This is done using powerful networks and outreach programs that is unique to us. Therefore, strongly positioning the project and brand in the marketplace. Through the mandate business we ensure uninterrupted cashflow from properties. Our offerings result is guaranteed business successes. ",
        image: "timeline-02",
        workingStep: [
            {
                stepTitle: "Follow this process",
                stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            },
            {
                stepTitle: "Working experience",
                stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            },
        ]
    },
    {
        id: "3",
        date: "Step-2",
        title: "Asset Monetisation & Liquidity Generation",
        description: "Our asset monetization & liquidity generation is backbone of our performance. Ensuring high standards of transparency and support; channel partners love to work with us. We follow functional processes that lead to building trust and transparency; these are short supplies in the industry.",
        image: "timeline-03",
        workingStep: [
            {
                stepTitle: "Follow this process",
                stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            },
            {
                stepTitle: "Working experience",
                stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            },
        ]
    },
    {
        id: "4",
        date: "Step-2",
        title: "IT Solution & CRM",
        description: "Our technology is designed to bring functional benefits to our stake holders in a way that improves performance. Bringing customers, developers and channel partners transparency. Ensuring confidentiality & security our unique tech solutions delivers on ease of business performance. This makes us unique in the marketplace that leads to better connections and transparency.",
        image: "timeline-04",
        workingStep: [
            {
                stepTitle: "Follow this process",
                stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            },
            {
                stepTitle: "Working experience",
                stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            },
        ]
    },
    ,
    {
        id: "5",
        date: "Step-2",
        title: "Brand & Design Development",
        description: "Brand and designs that brings out commercial value for the project. By using our development principles, we design strategies and its expression creatively. Our solutions range across all brand and business function to the align the two together for a remarkable outcome.",
        image: "timeline-05",
        workingStep: [
            {
                stepTitle: "Follow this process",
                stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            },
            {
                stepTitle: "Working experience",
                stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            },
        ]
    }, 
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        {/* <div className="row row--30">
                                            <ul className="working-list row">
                                             {data.workingStep.map((data, index)=>(
                                              <li className="col-lg-6 col-md-6 col-12" key={index}> {data} </li>
                                            ))}
                                            </ul>
                                        </div> */}
                                      {/*   <div className="read-morebtn">
                                            <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <a className="btn-default btn-large round" href="/contact"><span>Get Started Now</span></a>
                                            </ScrollAnimation>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
