import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight, FiArrowDown } from "react-icons/fi";

const SlpitOne = () => {
    return (
            <div className="rn-splite-style">
                <div className="split-wrapper">
                        <div className="row no-gutters radius-10 align-items-stretch">
                            
                            <div className="col-lg-12 col-xl-6 col-12 order-last order-sm-first">
                                <div className="split-inner">
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <h4 className="title">Business Solutions</h4>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="description">Our business solution is designed around creating value and solutions for the industry. Our aim is to operate with high standards for developers channel partners and customers to bring brand and business success for them. We like to include global best practices for local expressions that maximise value and volume for our partners.</p>
                                    </ScrollAnimation>

                                    
                                  {/*   <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <ul className="split-list">
                                            <li>- Real Estate</li>
                                            <li>- Commercial</li>
                                            <li>- Warehousing</li>
                                            <li>- Retail</li>
                                        </ul>
                                    </ScrollAnimation> */}
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <div className="view-more-button mt--35">
                                        <a className="btn-default btn-icon custom no-shadow" href="#"><i className="icon"><FiArrowRight /></i></a>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                        </div>
                        <div className="col-lg-12 col-xl-6 col-12 parallax order-first order-sm-last" style={{background:'url(./images/split/split-02.jpg)',backgroundAttachment:'fixed',backgroundPosition:'right'}}>
                                    
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default SlpitOne
