import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight, FiArrowDown } from "react-icons/fi";

const SlpitOne = () => {
    return (
            <div className="rn-splite-style">
                <div className="split-wrapper">
                        <div className="row no-gutters radius-10 align-items-stretch">
                        <div className="col-lg-12 col-xl-6 col-12 parallax" style={{background:'url(./images/split/split-03.jpg)',backgroundAttachment:'fixed'}}>

                            </div>
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="split-inner">
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <h4 className="title">Development Principles</h4>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="description">Our development principles form the basis for everything we do at Plinthstone. From the people we hire to strategies we develop or how we build partnerships. This defines our working behaviors and practices. If something doesn’t qualify these principles; we simply don’t do it. This is our secret sauce for success.
</p>
                                    </ScrollAnimation>

                                    
                                   {/*  <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <ul className="split-list">
                                            <li>- Real Estate</li>
                                            <li>- Commercial</li>
                                            <li>- Warehousing</li>
                                            <li>- Retail</li>
                                        </ul>
                                    </ScrollAnimation> */}
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <div className="view-more-button mt--35">
                                        <a className="btn-default btn-icon custom no-shadow" href="#"><i className="icon"><FiArrowRight /></i></a>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default SlpitOne
