import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight, FiArrowDown } from "react-icons/fi";

const SlpitOne = () => {
    return (
            <div className="rn-splite-style">
                <div className="split-wrapper">
                        <div className="row no-gutters radius-10 align-items-stretch">
                            <div className="col-lg-12 col-xl-6 col-12 parallax" style={{background:'url(./images/split/split-01.jpg)',backgroundAttachment:'fixed'}}>
                                
                            </div>
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="split-inner">
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <h4 className="title">Service Spectrum</h4>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="description">Our service spectrum ranges to capture critical functions of real estate across segments. Our services are uniquely designed for our partners to create brand and business value. As an offshoot of our development principles, our services reflect positive outcomes that benefit our partners in business sustenance while unlocking potentials across business verticals; resulting in unique positioning & revenue growth.</p>
                                    </ScrollAnimation>

                                    
                                    {/* <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <ul className="split-list">
                                            <li>- Real Estate</li>
                                            <li>- Commercial</li>
                                            <li>- Warehousing</li>
                                            <li>- Retail</li>
                                        </ul>
                                    </ScrollAnimation> */}
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <div className="view-more-button mt--35">
                                            <a className="btn-default btn-icon custom no-shadow" href="#"><i className="icon"><FiArrowRight /></i></a>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default SlpitOne
