import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'team-01',
        name: 'Harish Sharma',
        designation: 'Founder & CEO',
        location: 'Mumbai, India', 
        description: 'Accomplished business transformation expert with experience in Real Estate Advisory, Stock broking & Wealth management, Institutional Equities involving both start-up and growth organisations. Track record of over 20 years with proven success in building profitable businesses across diversified segments, new market identification and ensuring sustainability across business cycles. Harish has over the years demonstrated a balanced approach towards building high performance teams, risk management and client centric processes.',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: '#'
            },
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiInstagram />,
                url: '#'
            },
        ]
       
    },
    {
        image: 'team-02',
        name: 'Nitin Parashar',
        designation: 'Head - Business Development',
        location: 'Mumbai, India', 
        description: 'Experienced Head Of Real Estate with a demonstrated history of working in the real estate industry. Skilled in Funding, Investment Properties, Due Diligence, Private Equity, and Real Estate. Strong consulting professional with a MBA with dual specialisation - Finance and Hospital Administration from IMS Indore.',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: '#'
            },
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiLinkedin />,
                url: '#'
            },
        ]
      
    },
    {
        image: 'team-03',
        name: 'Manan Shah',
        designation: 'Chief Operating Officer',
        location: 'Mumbai, India', 
        description: 'Experienced Chief Operating Officer with a demonstrated history of working in the Financial Services and Real Estate industry. Have a customer centric vision and business acumen results in consistent development of powerful business strategies supported by cost effective, high performance IT infrastructures and applications, Have Expertise in Operational Management ensuring the running of the business well-oiled. Skilled in Business Strategy, Financial Analysis, Business Analysis, Tech Platform Development. Have a degree of BE & PGBDM focused in Finance from N.L.Dalmia Institute of Management Studies and Research. ',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: '#'
            },
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiInstagram />,
                url: '#'
            },
        ]
        
    },
    {
        image: 'team-04',
        name: 'Saurav Shaw',
        designation: 'Chief Marketing Officer',
        location: 'Mumbai, India', 
        description: '',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: '#'
            },
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiInstagram />,
                url: '#'
            },
        ]
        
    },
    {
        image: 'team-05',
        name: 'Siddharth Aggarwal',
        designation: 'Head - Sales',
        location: 'Mumbai, India', 
        description: '',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: '#'
            },
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiInstagram />,
                url: '#'
            },
        ]
        
    },
    {
        image: 'team-06',
        name: 'Avinash Sahay',
        designation: 'Head - Business Development',
        location: 'Mumbai, India', 
        description: '',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: '#'
            },
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiInstagram />,
                url: '#'
            },
        ]
        
    },
    {
        image: 'team-07',
        name: 'Anupama Jha',
        designation: 'Head - People Partner',
        location: 'Mumbai, India', 
        description: '',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: '#'
            },
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiInstagram />,
                url: '#'
            },
        ]
        
    },
]


const TeamOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15" style={{alignItems: 'center', justifyContent: 'center'}}>
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`rn-team ${teamStyle}`}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/team/${data.image}.jpg`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <h3 className="title">{data.name}</h3>
                                    <p className="subtitle">{data.designation}</p>
                                    {/* <span className="team-form">
                                        <img src="./images/team/location.svg" alt="Corporate React Template" />
                                        <span className="location">{data.location}</span> 
                                    </span>*/}
                                    {/* <p className="description">{data.description}</p> */}

                                    {/* <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                        )}
                                    </ul> */}
                                </figcaption>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TeamOne;
